import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const addIcon: IconProp = ["far", "circle-plus"];
export const alertIcon: IconProp = ["fal", "circle-exclamation"];
export const arrowRightIcon: IconProp = ["fas", "arrow-right"];
export const arrowLeftIcon: IconProp = ["fas", "arrow-left"];
export const bookIcon: IconProp = ["fas", "book"];
export const caretDownIcon: IconProp = ["fas", "caret-down"];
export const checkmarkIcon: IconProp = ["fal", "check"];
export const chevronRightIcon: IconProp = ["far", "chevron-right"];
export const circleCheckmarkIcon: IconProp = ["fal", "circle-check"];
export const circleCheckmarkAltIcon: IconProp = ["fas", "circle-check"];
export const circleCloseIcon: IconProp = ["fal", "circle-xmark"];
export const circleMinusIcon: IconProp = ["fas", "circle-minus"];
export const circleUserIcon: IconProp = ["fas", "circle-user"];
export const closeIcon: IconProp = ["fal", "xmark"];
export const copyIcon: IconProp = ["far", "copy"];
export const editIcon: IconProp = ["far", "pen-to-square"];
export const emailIcon: IconProp = ["far", "envelope"];
export const exportIcon: IconProp = ["far", "file-export"];
export const fileCheckIcon: IconProp = ["far", "file-check"];
export const filesIcon: IconProp = ["far", "files"];
export const fileSlashIcon: IconProp = ["fas", "file-slash"];
export const filtersIcon: IconProp = ["fal", "filters"];
export const deleteIcon: IconProp = ["far", "trash"];
export const infoIcon: IconProp = ["far", "circle-info"];
export const linkIcon: IconProp = ["far", "link-simple"];
export const lockIcon: IconProp = ["far", "lock-keyhole"];
export const lockOpenIcon: IconProp = ["far", "lock-keyhole-open"];
export const logoutIcon: IconProp = ["far", "arrow-right-to-bracket"];
export const navigateToIcon: IconProp = ["far", "arrow-up-right-from-square"];
export const noContactsIcon: IconProp = ["fas", "user-slash"];
export const noLinksIcon: IconProp = ["far", "link-simple-slash"];
export const noTableDataIcon: IconProp = ["fas", "subtitles-slash"];
export const phoneIcon: IconProp = ["far", "phone"];
export const refreshIcon: IconProp = ["far", "arrows-rotate"];
export const settingsIcon: IconProp = ["far", "gear"];
export const userIcon: IconProp = ["far", "user"];
export const weatherIcon: IconProp = ["far", "cloud-bolt-sun"];
