import { IdsLink, IdsRadioButton } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { useState } from "react";

import { Menu, MenuItemType } from "components/Menu";
import { Tooltip } from "components/Tooltip";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";
import { TrmFilters } from "pages/ExploreTrms/utils";

import { useGetPublisherTrmsQuery, useGetMeasuresByTrmQuery, useUpdateTrmStatusMutation } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";

import type { PublisherTrms, MeasureListItem, TrmDetails } from "types";

import { circleMinusIcon, navigateToIcon, circleCheckmarkAltIcon, settingsIcon } from "utils/icons";

export const TrmStatusMenu: React.FC<{
    selectedTrm: TrmDetails;
    selectedMeasure?: string;
}> = ({ selectedTrm, selectedMeasure }) => {
    const dispatch = useAppDispatch();

    const [activeTrm, setActiveTrm] = useState<PublisherTrms>();
    const [currentApplicableYear, setCurrentApplicableYear] = useState(selectedTrm.applicableYear);

    const { data: publisherTrms, isLoading: isLoadingPublisherTrms } = useGetPublisherTrmsQuery({
        publisherNumber: selectedTrm.publisherNumber,
    });

    const { data: measures } = useGetMeasuresByTrmQuery({ trmNumber: activeTrm?.trmNumber ?? "" }, { skip: activeTrm === undefined });

    const [updateTrmStatus, updateTrmStatusStatus] = useUpdateTrmStatusMutation();

    if (!activeTrm && publisherTrms && !isEmpty(publisherTrms)) {
        const activeTrm = publisherTrms.find((trm) => trm.active);

        setActiveTrm(activeTrm);
    }

    const onSave = async () => {
        // Do nothing if either saving is in progress or TRMs have not loaded
        if (updateTrmStatusStatus.isLoading || !publisherTrms) {
            return;
        }

        const newActiveTrmNumber = publisherTrms.find((trm) => trm.applicableYear === currentApplicableYear)?.trmNumber;

        if (!newActiveTrmNumber) {
            return;
        }

        const response = await updateTrmStatus({
            activeTrmNumber: newActiveTrmNumber,
            currentTrmNumber: selectedTrm.trmNumber,
            publisherNumber: selectedTrm.publisherNumber,
            trmStatus: {
                active: true,
            },
        }).unwrap();

        if (response.responseStatus === "success") {
            // If active TRM updated to inactive, change TRMs and measures filter
            // to "Show All"
            // If inactive TRM updated to active, change TRMs and measures filter
            // to "Show Active"
            const filter = selectedTrm.active ? TrmFilters.All : TrmFilters.Active;

            dispatch(selectorChange({ selector: "filter", value: filter, persist: true }));
        }
    };

    const getItems = () => {
        let items: MenuItemType[] = [];

        if (selectedTrm.applicableYear !== null && !selectedTrm.active) {
            items = [getCurrentlyBrowsingItem(selectedTrm.applicableYear)];
        }

        items = [...items, getActiveVersionItem(selectedTrm.active, activeTrm, selectedMeasure, measures)];

        if (publisherTrms && publisherTrms.length > 1) {
            items = [
                ...items,
                dividerItem,
                getActiveVersionActionItem({
                    applicableYear: currentApplicableYear,
                    publisherTrms,
                    setApplicableYear: setCurrentApplicableYear,
                    onSave,
                }),
            ];
        }

        return items;
    };

    if (isLoadingPublisherTrms) {
        return null;
    }

    return (
        <Menu
            active={selectedTrm.active}
            labelValue={selectedTrm.active ? "Active" : "Inactive"}
            label="Status"
            items={getItems()}
            onClose={() => setCurrentApplicableYear(selectedTrm.applicableYear)}
        />
    );
};

const dividerItem: MenuItemType = { variant: "divider" };

const getCurrentlyBrowsingItem = (applicableYear: number): MenuItemType => ({
    heading: "Currently browsing",
    iconLeft: circleMinusIcon,
    itemValue: `${applicableYear}`,
});

const getActiveVersionItem = (
    isSelectedTrmActive: boolean,
    activeTrm?: PublisherTrms,
    measureNumber?: string,
    measures?: MeasureListItem[],
): MenuItemType => {
    const measure = measures?.find((measure) => measure.measureNumber === measureNumber);
    let tooltipMessage = "";
    let url = "";

    if (!isSelectedTrmActive && activeTrm) {
        tooltipMessage = measureNumber
            ? "The selected measure is not available in the active version. You will be redirected to the TRM page."
            : "You will be redirected to the TRM page.";
        url = `${window.location.protocol}//${window.location.host}/${activeTrm.trmNumber}`;

        if (measure) {
            tooltipMessage = `Version ${activeTrm.applicableYear} / ${measure.measureName}`;
            url += `/${measure.measureNumber}`;
        }
    }

    return {
        heading: "Active version",
        customIconRight: !isSelectedTrmActive ? (
            <Tooltip message={tooltipMessage} placement="right">
                <IdsLink href={url} target="_blank" isInline size="md">
                    <FontAwesomeIcon icon={navigateToIcon} size="lg" />
                </IdsLink>
            </Tooltip>
        ) : undefined,
        iconLeft: circleCheckmarkAltIcon,
        iconLeftStyle: {
            color: "var(--theme-feedback-success)",
        },
        itemValue: activeTrm?.applicableYear ? `${activeTrm.applicableYear}` : "",
    };
};

const getActiveVersionActionItem = ({
    applicableYear,
    publisherTrms,
    setApplicableYear,
    onSave,
}: {
    applicableYear: number;
    publisherTrms: PublisherTrms[];
    setApplicableYear: (applicableYear: number) => void;
    onSave: () => void;
}): MenuItemType => ({
    variant: "action",
    iconRight: true,
    iconLeft: settingsIcon,
    itemValue: "Change active version",
    subHeading: "Select to activate",
    subActions: [
        {
            variant: "primary",
            label: "Save",
            onClick: onSave,
        },
        {
            variant: "tertiary",
            label: "Cancel",
        },
    ],
    subItems: publisherTrms.map((trm) => (
        <IdsRadioButton
            key={trm.applicableYear}
            idValue={`${trm.applicableYear}`}
            name="applicable-year"
            label={`${trm.applicableYear}`}
            defaultChecked={trm.applicableYear === applicableYear}
            clickHandler={() => setApplicableYear(trm.applicableYear)}
        />
    )),
});
