import { isEmpty } from "lodash";
import { useCallback, useRef, useState } from "react";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";

import { IdsContainer, IdsRow, IdsCol } from "@emergn-infinity/ids-react";

import { Sidebar } from "./Sidebar";
import { TrmDetailsTile } from "./TrmDetails/TrmDetailsTile";
import { MeasureLivesTile } from "./MeasureLives/MeasureLivesTile";
import { MeasureCostsTile } from "./MeasureCosts/MeasureCostsTile";
import { AlgorithmsTile } from "./Algorithms/AlgorithmsTile";
import { VariablesTile } from "./Variables/VariablesTile";
import { SavingsCalculationTile } from "./SavingsCalculationTile";
import { MeasureDetailsTile } from "./MeasureDetailsTile";

import { ChooseItem } from "components/ChooseItem";

import { bookIcon, fileSlashIcon } from "utils/icons";

import { AlgorithmVariable, MeasureAlgorithm, MeasureVariable } from "types";
import { copyVariablesToClipboard } from "./utils";

export const ExploreTrms = () => {
    const dispatch = useAppDispatch();

    const { selectedTrm, selectedMeasure } = useAppSelector((state) => state.exploreTrms);

    const [selectedAlgorithm, setSelectedAlgorithm] = useState<MeasureAlgorithm>();
    const [tempAssignedCalculation, setTempAssignedCalculation] = useState<string>(); // this state handles lookup equations
    const [algorithmVariables, setAlgorithmVariables] = useState<AlgorithmVariable[]>([]);

    const selectTrmRef = useRef<HTMLIdsDropdownElement>(null);
    const selectMeasureRef = useRef<HTMLIdsDropdownElement>(null);

    const showTrmDetails = !isEmpty(selectedTrm);
    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);

    const onChooseClick = useCallback((ref: React.RefObject<HTMLIdsDropdownElement>) => {
        setTimeout(() => {
            ref.current?.querySelector("ids-icon")?.click();
        }, 100);
    }, []);

    const onTrmSelect = (trmNumber: string) => {
        dispatch(selectorChange({ selector: "trm", value: trmNumber }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onMeasureSelect = (measureNumber: string) => {
        dispatch(selectorChange({ selector: "measure", value: measureNumber }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onMeasureClear = () => {
        dispatch(selectorChange({ selector: "measure", value: "" }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onAlgorithmSelect = (algorithm: MeasureAlgorithm) => {
        setSelectedAlgorithm(algorithm);
        setTempAssignedCalculation(undefined);
        setAlgorithmVariables([]);
    };

    const onVariableChange = (variable: AlgorithmVariable) => {
        setAlgorithmVariables((prev) => {
            const index = prev.findIndex((v) => v.name === variable.name);
            if (index === -1) {
                return [...prev, variable];
            }

            return prev.map((v, i) => (i === index ? variable : v));
        });
    };

    const onExportVariables = async (variables: MeasureVariable[]) => {
        await copyVariablesToClipboard(variables, algorithmVariables);
    };

    return (
        <IdsContainer fullHeight customClasses="p-0">
            <IdsRow noGutters>
                <Sidebar
                    selectedTrm={selectedTrm}
                    selectedMeasure={selectedMeasure}
                    selectTrmRef={selectTrmRef}
                    selectMeasureRef={selectMeasureRef}
                    onTrmSelect={onTrmSelect}
                    onMeasureSelect={onMeasureSelect}
                    onMeasureClear={onMeasureClear}
                />
                <IdsCol xs="9">
                    {showTrmDetails ? (
                        <div key={`${selectedTrm}`} className="flex-column fill-height with-scroll gap-4 p-4">
                            <TrmDetailsTile selectedTrm={selectedTrm} selectedMeasure={selectedMeasure} />
                            {showMainContent ? (
                                <div key={`${selectedTrm}-${selectedMeasure}`} className="flex-column gap-4">
                                    <MeasureDetailsTile selectedMeasure={selectedMeasure} />
                                    <MeasureLivesTile selectedMeasure={selectedMeasure} />
                                    <MeasureCostsTile selectedMeasure={selectedMeasure} />
                                    <AlgorithmsTile
                                        selectedMeasure={selectedMeasure}
                                        selectedAlgorithmNumber={selectedAlgorithm?.algorithmNumber}
                                        onAlgorithmSelect={onAlgorithmSelect}
                                    />
                                    <VariablesTile
                                        selectedMeasure={selectedMeasure}
                                        algorithm={selectedAlgorithm}
                                        algorithmVariables={algorithmVariables}
                                        tempAssignedCalculation={tempAssignedCalculation}
                                        setTempAssignedCalculation={setTempAssignedCalculation}
                                        onVariableChange={onVariableChange}
                                        onExport={onExportVariables}
                                    />
                                    <SavingsCalculationTile
                                        algorithm={selectedAlgorithm}
                                        tempAssignedCalculation={tempAssignedCalculation}
                                        variables={algorithmVariables}
                                    />
                                </div>
                            ) : (
                                <ChooseItem
                                    icon={fileSlashIcon}
                                    title="No Measure Chosen"
                                    message="Please select a measure to view its details."
                                    actionLabel="Choose Measure"
                                    onActionClick={() => onChooseClick(selectMeasureRef)}
                                />
                            )}
                        </div>
                    ) : (
                        <ChooseItem
                            icon={bookIcon}
                            title="No TRM Chosen"
                            message="Please select a TRM to view its details."
                            actionLabel="Choose TRM"
                            onActionClick={() => onChooseClick(selectTrmRef)}
                        />
                    )}
                </IdsCol>
            </IdsRow>
        </IdsContainer>
    );
};
