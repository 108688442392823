import { isEmpty } from "lodash";

import { IdsModal, IdsText, IdsDivider, IdsButton, IdsLink } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ContactBlock } from "./ContactBlock";

import { IconButton } from "components/IconButton";

import { InfoItem } from "pages/ExploreTrms/InfoItem";

import { copyToClipboard, getPhoneNumber } from "pages/ExploreTrms/utils";
import { copyIcon, navigateToIcon } from "utils/icons";

const CONTACT_MODAL_STYLE = {
    width: "452px",
    maxWidth: "100%",
};

export const ContactView: React.FC<{
    firstName: string;
    lastName: string;
    company?: string;
    title?: string;
    phone?: string;
    extension?: string;
    email?: string;
    onClose: () => void;
}> = ({ firstName, lastName, company, title, phone, extension, email, onClose }) => {
    const phoneNumber = getPhoneNumber(phone, extension);

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Contact
                </IdsText>
            </div>
            <div slot="main" className="flex-column gap-3" style={CONTACT_MODAL_STYLE}>
                <div>
                    <ContactBlock company={company} firstName={firstName} lastName={lastName} direction="column" bold />
                    <div className="pt-3 pb-3">
                        <IdsDivider />
                    </div>
                    <InfoItem label="Company" value={company} />
                </div>
                <InfoItem label="Title" value={isEmpty(title) ? "-" : title} />
                <InfoItem
                    label="Phone"
                    value={
                        phoneNumber ? (
                            <div className="flex-row align-center justify-space-between">
                                <span>{phoneNumber}</span>
                                <IconButton icon={copyIcon} size="lg" onClick={() => copyToClipboard(phoneNumber)} />
                            </div>
                        ) : (
                            "-"
                        )
                    }
                />
                <InfoItem
                    label="Email"
                    value={
                        email ? (
                            <div className="flex-row align-center justify-space-between">
                                <span>{email}</span>
                                <div className="flex-row align-center gap-2">
                                    <IconButton icon={copyIcon} size="lg" onClick={() => copyToClipboard(email)} />
                                    <IdsLink href={`mailto:${email}`} target="_self">
                                        <FontAwesomeIcon icon={navigateToIcon} size="lg" />
                                    </IdsLink>
                                </div>
                            </div>
                        ) : (
                            "-"
                        )
                    }
                />
            </div>
            <div slot="footer">
                <IdsButton variant="secondary" clickHandler={onClose}>
                    Close
                </IdsButton>
            </div>
        </IdsModal>
    );
};
