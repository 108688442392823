import { IdsButton } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch } from "store/hooks";
import { resetLogin } from "store/login/actions";

import { arrowLeftIcon } from "utils/icons";

import { clearBrowserUrl } from "utils/window";

export const ReturnButton: React.FC<{
    isForForm?: boolean;
    onReturn?: () => void;
}> = ({ isForForm, onReturn }) => {
    const dispatch = useAppDispatch();

    const onReturnClick = () => {
        onReturn?.();

        clearBrowserUrl();

        dispatch(resetLogin());
    };

    return (
        <IdsButton
            fullWidth={!isForForm}
            variant={isForForm ? "tertiary" : "primary"}
            padding={isForForm ? "sm" : "lg"}
            clickHandler={onReturnClick}
        >
            <div className="flex-row gap-2 align-center">
                {isForForm ? (
                    <>
                        <FontAwesomeIcon icon={arrowLeftIcon} fixedWidth />
                        Return
                    </>
                ) : (
                    "Return To Login Page"
                )}
            </div>
        </IdsButton>
    );
};
