import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

import { IdsButton } from "@emergn-infinity/ids-react";

import "./style.scss";

// Currently supported sizes are - default, lg, xl
export const IconButton: React.FC<{
    icon: IconProp;
    color?: string;
    size?: SizeProp;
    square?: boolean;
    padding?: PaddingProp;
    title?: string;
    isDisabled?: boolean;
    onClick?: () => void;
}> = ({ icon, color, size, square, padding, title, isDisabled, onClick }) => {
    let sizeClassName = "";
    let paddingClassName = "";

    if (size) {
        sizeClassName = `size-${size}`;
    }
    if (padding) {
        paddingClassName = `padding-${padding}`;
    }

    return (
        <IdsButton
            customClasses={cn("icon-button", sizeClassName, paddingClassName, {
                "square-button": square,
            })}
            variant="tertiary"
            padding={padding}
            title={title ?? ""}
            isDisabled={isDisabled}
            clickHandler={onClick}
        >
            <FontAwesomeIcon icon={icon} color={color} size={size} fixedWidth />
        </IdsButton>
    );
};

type PaddingProp = "xs" | "sm" | "md" | "lg";
