import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    useGetAssumptionsQuery,
    useUpdateAssumptionMutation,
    useUpdateAssumptionEquationOrNameMutation,
    useGetLookupByPremisesQuery,
} from "store/apiSlice";

import { IdsButton, IdsButtonGroup, IdsModal, IdsText, IdsTextInput } from "@emergn-infinity/ids-react";

import { EditFormTags } from "pages/ExploreTrms/EditFormTags";
import { VariableForm, validateAssumption } from "pages/ExploreTrms/Variables/VariableEdit";
import { ManageLookups } from "pages/ExploreTrms/ManageLookups";

import { extractVariables, getAccompanyingLayers, printPromiseErrors, AlgorithmErrors } from "pages/ExploreTrms/utils";

import { circleCheckmarkIcon, circleCloseIcon } from "utils/icons";

import type { Assumption, AssumptionModel, AssumptionUpdateModel, Measure } from "types";

const ALGORITHM_VARIABLES_MODAL_STYLE = {
    width: "996px",
    maxWidth: "100%",
};

const initialManageLookups = {
    add: false,
    view: false,
};

export const AlgorithmVariablesModal: React.FC<{
    title: string;
    measure: Measure;
    algorithmNumber: string;
    algorithm: string;
    onClose: () => void;
}> = ({ title, algorithmNumber, algorithm, measure, onClose }) => {
    const [manageLookups, setManageLookups] = useState(initialManageLookups);

    const [model, setModel] = useState<{
        [key: string]: AssumptionModel;
    }>({});

    const [errors, setErrors] = useState<{
        [key: string]: {
            [K in keyof AssumptionModel]?: string;
        };
    }>({});

    const [equationErrors, setEquationErrors] = useState<{
        [key: string]: {
            [K in keyof AlgorithmErrors]?: string;
        };
    }>({});

    const [assumption, setAssumption] = useState<Assumption>();
    const [updateModelInitiator, setUpdateModelInitiator] = useState(isEmpty(model)); // flag for when to update model state

    const { data: assumptions, isLoading: isLoadingAssumptions, isFetching, isError } = useGetAssumptionsQuery({ algorithmNumber });

    const { data: lookups = [], isLoading: isLoadingLookups } = useGetLookupByPremisesQuery({
        trmNumber: measure.trmNumber,
        lookupType: "Assumption",
    });

    const [updateAssumption, updateAssumptionStatus] = useUpdateAssumptionMutation();
    const [updateAsmpEquationOrName, updateAsmpEquationOrNameStatus] = useUpdateAssumptionEquationOrNameMutation();

    const isLoading = isLoadingLookups || isLoadingAssumptions;

    const lookupBreadcrumb = useMemo(() => {
        const variable = assumption?.variable ?? "";

        return [<IdsText key={variable}>{variable}</IdsText>];
    }, [assumption]);

    const assumptionList = useMemo(() => {
        let assumptionList: ({
            uiLayer: number;
        } & Assumption)[] = [];

        if (assumptions && !isEmpty(assumptions)) {
            assumptions.forEach((asmp) => {
                assumptionList.push({ ...asmp, uiLayer: 1 });

                if (asmp.equation) {
                    const variables = extractVariables(asmp.equation);

                    variables.forEach((v) => {
                        // Remove angle brackets to get the variable name
                        const variableName = v.substring(1, v.length - 1);

                        const childAssumption = assumptions.find((a) => a.variable === variableName);

                        if (childAssumption) {
                            assumptionList.push({ ...childAssumption, uiLayer: 2 });
                        }
                    });
                }
            });
        }

        return assumptionList;
    }, [assumptions]);

    // Select first assumption
    if (!assumption && assumptions && !isEmpty(assumptions)) {
        setAssumption(assumptions[0]);
    }

    // New assumptions are created after updating assumption equation,
    // so model state needs to be updated with newly added assumptions
    if (isFetching && !updateModelInitiator) {
        setUpdateModelInitiator(true);
    }

    // Update model state with all available assumptions
    if (assumptions && !isEmpty(assumptions) && !isFetching && updateModelInitiator) {
        const newModel = { ...model };
        const newErrors = { ...errors };

        assumptions.forEach((asmp) => {
            // If assumption is already part of the model state,
            // only overwrite layer property
            if (newModel[asmp.assumptionNumber]) {
                newModel[asmp.assumptionNumber] = {
                    ...newModel[asmp.assumptionNumber],
                    layer: asmp.layer,
                };
            } else {
                const newAsmp = {
                    ...asmp,
                    assignedValue: asmp.assignedValue ? String(asmp.assignedValue) : undefined,
                    assignedValue_Min: asmp.assignedValue_Min ? String(asmp.assignedValue_Min) : undefined,
                    assignedValue_Max: asmp.assignedValue_Max ? String(asmp.assignedValue_Max) : undefined,
                    varSourceYear: asmp.varSourceYear ? String(asmp.varSourceYear) : undefined,
                };

                newModel[asmp.assumptionNumber] = newAsmp;
                newErrors[asmp.assumptionNumber] = validateAssumption(newAsmp);
            }
        });

        setModel(newModel);
        setErrors(newErrors);

        // To avoid infinite loop, change flag to false
        setUpdateModelInitiator(false);
    }

    const onVariableChange = (variable: Assumption) => {
        setAssumption(variable);
    };

    const onSaveEquation = async () => {
        // Do nothing if saving is in progress
        if (updateAsmpEquationOrNameStatus.isLoading) {
            return;
        }

        if (assumption) {
            if (!isEmpty(equationErrors[assumption.assumptionNumber])) {
                return;
            }

            try {
                const asmpWithEquations = assumptions?.filter((asmp) => asmp.equation) ?? [];

                let accompanyingLayers = [
                    {
                        // Assumption that gets equation defined will have the lowest layer (layer = 1).
                        // It does not matter what layer gets set here, it will get reevaluated
                        // in getAccompanyingLayers function
                        assumptionNumber: assumption.assumptionNumber,
                        layer: 0,
                    },
                ];

                accompanyingLayers = getAccompanyingLayers(assumption.variable, asmpWithEquations, accompanyingLayers);

                const assumptionModel = {
                    equation: model[assumption.assumptionNumber].equation,
                    accompanyingLayers,
                    algorithmNumber,
                };

                await updateAsmpEquationOrName({ assumptionNumber: assumption.assumptionNumber, assumption: assumptionModel }).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (updateAssumptionStatus.isLoading) {
            return;
        }

        // Validate assumptions
        setErrors({});

        const errors = validateAssumptions(model);

        if (!isEmpty(errors)) {
            setErrors(errors);

            // Select variable with error
            const errorKey = Object.keys(errors).find((key) => !isEmpty(errors[key]));
            const invalidAssumption = assumptions?.find((a) => a.assumptionNumber === errorKey);

            if (invalidAssumption) {
                setAssumption(invalidAssumption);

                setTimeout(() => {
                    const input = document.querySelector<HTMLInputElement>(`ids-field-wrapper.is-invalid input`);
                    if (input) {
                        input.scrollIntoView({ behavior: "smooth", block: "center" });
                        input.focus();
                    }
                }, 0);
            }

            return;
        }

        // update all assumptions
        if (model) {
            try {
                const assumptionNumbers = Object.keys(model);

                const promises = assumptionNumbers.map((key, index) => {
                    const assumptionModel = {
                        ...model[key],
                        assignedValue: model[key].assignedValue ? Number(model[key].assignedValue) : undefined,
                        assignedValue_Min: model[key].assignedValue_Min ? Number(model[key].assignedValue_Min) : undefined,
                        assignedValue_Max: model[key].assignedValue_Max ? Number(model[key].assignedValue_Max) : undefined,
                        varSourceYear: model[key].varSourceYear ? Number(model[key].varSourceYear) : undefined,
                    };

                    return updateAssumption({
                        assumptionNumber: key,
                        assumption: assumptionModel,
                        clearAlgorithm: index === assumptionNumbers.length - 1,
                    });
                });

                const result = await Promise.allSettled(promises);

                const hasErrors = printPromiseErrors(result);

                // Do not close if any update failed
                if (hasErrors) {
                    return;
                }

                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onVariableFormChange = (key: string, value: AssumptionModel) => {
        setModel({ ...model, [key]: value });

        const variableErrors = validateAssumption(value);
        setErrors({
            ...errors,
            [key]: variableErrors,
        });
    };

    const onEquationErrorChange = (key: string, value: AlgorithmErrors) => {
        setEquationErrors({ ...equationErrors, [key]: value });
    };

    if (manageLookups.add || manageLookups.view) {
        return (
            <ManageLookups
                measure={measure}
                lookupType="Assumption"
                title={title}
                breadcrumb={lookupBreadcrumb}
                lookupByPremise={manageLookups.view && assumption ? model[assumption.assumptionNumber].varLookupBy! : undefined}
                modalStyle={ALGORITHM_VARIABLES_MODAL_STYLE}
                onClose={() => setManageLookups(initialManageLookups)}
            />
        );
    }

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={ALGORITHM_VARIABLES_MODAL_STYLE}>
                {isLoading && <IdsText size="md">Loading...</IdsText>}
                {isError && <IdsText size="md">Error loading Variables</IdsText>}
                {!isLoading && !isError && algorithm && (
                    <div className="flex-column gap-4">
                        <div>
                            <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} />
                            <IdsTextInput defaultValue={algorithm} isReadonly />
                        </div>
                        <div
                            className="flex-row gap-2 fill-height"
                            style={{ borderTop: "1px solid var(--ids-divider-border-color-default)" }}
                        >
                            <div
                                className="flex-column p-2"
                                style={{ width: "20rem", borderRight: "1px solid var(--ids-divider-border-color-default)" }}
                            >
                                {assumptionList.map((variable) => (
                                    <button
                                        key={`${variable.assumptionNumber}-layer-${variable.uiLayer}`}
                                        className="py-2 pr-2 border-none"
                                        onClick={() => onVariableChange(variable)}
                                        style={{
                                            backgroundColor:
                                                variable.assumptionNumber === assumption?.assumptionNumber && variable.uiLayer === 1
                                                    ? "var(--ids-semantic-background-color-brand-a-subtlest-hover)"
                                                    : "transparent",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            className="flex-row align-center gap-2"
                                            style={{
                                                borderLeft:
                                                    variable.assumptionNumber === assumption?.assumptionNumber && variable.uiLayer === 1
                                                        ? "5px solid var(--ids-semantic-border-color-brand-a-accent)"
                                                        : "5px solid transparent",
                                                paddingLeft: variable.uiLayer >= 5 ? "4.5rem" : `${variable.uiLayer - 0.5}rem`,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={isEmpty(errors[variable.assumptionNumber]) ? circleCheckmarkIcon : circleCloseIcon}
                                                size="lg"
                                                fixedWidth
                                                color={
                                                    isEmpty(errors[variable.assumptionNumber])
                                                        ? "var(--ids-semantic-ink-color-success-subtle)"
                                                        : "var(--ids-semantic-ink-color-critical-subtle)"
                                                }
                                            />
                                            <IdsText
                                                size="md"
                                                weight={
                                                    variable.assumptionNumber === assumption?.assumptionNumber && variable.uiLayer === 1
                                                        ? "bold"
                                                        : undefined
                                                }
                                                style={{
                                                    color:
                                                        variable.assumptionNumber === assumption?.assumptionNumber && variable.uiLayer === 1
                                                            ? "var(--ids-semantic-ink-color-brand-a-accent)"
                                                            : undefined,
                                                }}
                                            >{`<${variable.variable}>`}</IdsText>
                                        </div>
                                    </button>
                                ))}
                            </div>
                            <div className="flex-grow pl-3">
                                {assumption && (
                                    <VariableForm
                                        key={assumption.assumptionNumber}
                                        assumption={assumption}
                                        isLoading={updateAssumptionStatus.isLoading}
                                        lookups={lookups}
                                        model={model[assumption.assumptionNumber]}
                                        errors={errors[assumption.assumptionNumber]}
                                        equationErrors={equationErrors[assumption.assumptionNumber]}
                                        setModel={(value) => onVariableFormChange(assumption.assumptionNumber, value)}
                                        setEquationErrors={(value) => onEquationErrorChange(assumption.assumptionNumber, value)}
                                        onAddLookupTable={() => setManageLookups({ add: true, view: false })}
                                        onViewLookupTable={() => setManageLookups({ add: false, view: true })}
                                        onSaveEquation={onSaveEquation}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Close
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSave}>
                        <>{updateAssumptionStatus.isLoading ? "Saving..." : "Save"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

const validateAssumptions = (model: { [key: string]: AssumptionModel }) => {
    const errors: {
        [key: string]: {
            [K in keyof AssumptionUpdateModel]?: string;
        };
    } = {};

    Object.keys(model).forEach((key) => {
        const variableErrors = validateAssumption(model[key]);

        if (!isEmpty(variableErrors)) {
            errors[key] = variableErrors;
        }
    });

    return errors;
};
