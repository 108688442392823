import { IdsButtonGroup, IdsButton, IdsLink } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { useState } from "react";

import { TrmStatusMenu } from "./TrmStatusMenu";
import { TrmEdit } from "./TrmEdit";
import { TrmUpdateVersion } from "./TrmUpdateVersion";
import { ContactView } from "./ContactView";
import { ContactBlock } from "./ContactBlock";

import { IconButton } from "components/IconButton";
import { Tile } from "components/Tile";

import { InfoItem } from "pages/ExploreTrms/InfoItem";
import { TrmEditTabs } from "pages/ExploreTrms/utils";

import { useGetContactsQuery, useGetPublisherDetailsQuery, useGetTrmDetailsQuery, useGetTrmLinksQuery } from "store/apiSlice";

import type { Contact } from "types";

import { TrmRights } from "utils/constants";
import { refreshIcon, editIcon, navigateToIcon, addIcon, linkIcon, infoIcon } from "utils/icons";
import { hasRights } from "utils/user";

export const TrmDetailsTile: React.FC<{
    selectedTrm: string;
    selectedMeasure?: string;
}> = ({ selectedTrm, selectedMeasure }) => {
    const [editDetails, setEditDetails] = useState<TrmEditTab>();
    const [isUpdateVersionActive, setIsUpdateVersionActive] = useState(false);
    const [selectedContact, setSelectedContact] = useState<Contact>();

    const editRights = hasRights(TrmRights);

    const { data: trmData, isError: isGetTrmDetailsError } = useGetTrmDetailsQuery(
        { trmNumber: selectedTrm },
        { skip: isEmpty(selectedTrm) },
    );
    const trmDetails = !isGetTrmDetailsError ? trmData : null;

    const { data: publisherData, isError: isGetPublisherDetailsError } = useGetPublisherDetailsQuery(
        { publisherNumber: trmDetails?.publisherNumber! },
        {
            skip: isEmpty(trmDetails?.publisherNumber),
        },
    );
    const publisherDetails = trmDetails && !isGetPublisherDetailsError ? publisherData : null;

    const {
        data: trmLinksData,
        isLoading: isLoadingTrmLinks,
        isError: isGetTrmLinksError,
    } = useGetTrmLinksQuery({ trmNumber: selectedTrm }, { skip: isEmpty(selectedTrm) });
    const trmLinks = trmDetails && !isGetTrmLinksError ? trmLinksData : null;

    const {
        data: contactsData,
        isLoading: isLoadingContacts,
        isError: isGetContactsError,
    } = useGetContactsQuery({ publisherNumber: trmDetails?.publisherNumber }, { skip: isEmpty(trmDetails?.publisherNumber) });
    const contacts = trmDetails && !isGetContactsError ? contactsData : null;

    let trmLinksElement = <>-</>;
    let contactsElement = <>-</>;

    if (trmLinks && isEmpty(trmLinks) && !isLoadingTrmLinks && editRights) {
        trmLinksElement = (
            <IdsButton variant="secondary" clickHandler={() => setEditDetails(TrmEditTabs.Links.id)}>
                <div className="flex-row align-center gap-2">
                    <FontAwesomeIcon icon={addIcon} size="lg" />
                    Add Link
                </div>
            </IdsButton>
        );
    } else if (trmLinks && !isEmpty(trmLinks) && !isLoadingTrmLinks) {
        trmLinksElement = (
            <div className="flex-column gap-1">
                {trmLinks?.map((link) => (
                    <IdsLink key={link.linkNumber} href={link.urlLink} target="_blank" isInline size="md">
                        <span>
                            <FontAwesomeIcon icon={linkIcon} /> {link.linkName} <FontAwesomeIcon icon={navigateToIcon} size="xs" />
                        </span>
                    </IdsLink>
                ))}
            </div>
        );
    }

    if (contacts && isEmpty(contacts) && !isLoadingContacts && editRights) {
        contactsElement = (
            <IdsButton variant="secondary" clickHandler={() => setEditDetails(TrmEditTabs.Contacts.id)}>
                <div className="flex-row align-center gap-2">
                    <FontAwesomeIcon icon={addIcon} size="lg" />
                    Add Contact
                </div>
            </IdsButton>
        );
    } else if (contacts && !isEmpty(contacts) && !isLoadingContacts) {
        contactsElement = (
            <div className="flex-column gap-1">
                {contacts?.map((contact) => (
                    <div key={contact.contactNumber} className="flex-row align-center justify-space-between">
                        <ContactBlock firstName={contact.firstName} lastName={contact.lastName} company={contact.company ?? undefined} />
                        <IconButton icon={infoIcon} size="lg" onClick={() => setSelectedContact(contact)} />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <Tile
            className="flex-one-in-row"
            title="TRM Details"
            action={
                editRights && (
                    <div className="flex-row align-center">
                        {trmDetails && (
                            <div key={`trm-status-${trmDetails?.active}`} className="pr-3">
                                <TrmStatusMenu selectedTrm={trmDetails} selectedMeasure={selectedMeasure} />
                            </div>
                        )}
                        <IdsButtonGroup customClasses="pl-3" spaceBetween="lg" style={{ borderLeft: "1px solid var(--theme-base-border)" }}>
                            <IdsButton variant="tertiary" padding="sm" clickHandler={() => setIsUpdateVersionActive(true)}>
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={refreshIcon} />
                                    Update TRM Version
                                </div>
                            </IdsButton>
                            <IdsButton variant="tertiary" padding="sm" clickHandler={() => setEditDetails(TrmEditTabs.TrmInformation.id)}>
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={editIcon} />
                                    Edit
                                </div>
                            </IdsButton>
                        </IdsButtonGroup>
                    </div>
                )
            }
        >
            {editDetails && trmDetails && publisherDetails && trmLinks && contacts && (
                <TrmEdit
                    trmDetails={trmDetails}
                    publisherDetails={publisherDetails}
                    trmLinks={trmLinks}
                    contacts={contacts}
                    tab={editDetails}
                    onClose={() => setEditDetails(undefined)}
                />
            )}
            {isUpdateVersionActive && trmDetails && trmLinks && (
                <TrmUpdateVersion trmDetails={trmDetails} trmLinks={trmLinks} onClose={() => setIsUpdateVersionActive(false)} />
            )}
            {selectedContact && (
                <ContactView
                    firstName={selectedContact.firstName}
                    lastName={selectedContact.lastName}
                    company={selectedContact.company ?? undefined}
                    title={selectedContact.title ?? undefined}
                    extension={selectedContact.extension ?? undefined}
                    phone={selectedContact.phone ?? undefined}
                    email={selectedContact.email ?? undefined}
                    onClose={() => setSelectedContact(undefined)}
                />
            )}
            <div className="flex-row p-3">
                <div className="flex-column w-70 gap-3">
                    <InfoItem
                        label="Publisher"
                        value={
                            publisherDetails?.publisherURL ? (
                                <IdsLink href={publisherDetails.publisherURL} target="_blank" isInline size="md">
                                    <span>
                                        {publisherDetails?.publisherName} <FontAwesomeIcon icon={navigateToIcon} size="xs" />
                                    </span>
                                </IdsLink>
                            ) : (
                                (publisherDetails?.publisherName ?? "-")
                            )
                        }
                    />
                    <InfoItem label="Authored By" value={trmDetails?.preparedBy ?? "-"} />

                    <div className="flex-row gap-3">
                        <InfoItem className="flex-one-in-row" label="Applicable Year" value={trmDetails?.applicableYear ?? "-"} />
                        <InfoItem className="flex-one-in-row" label="Year Authored" value={trmDetails?.yearPrepared ?? "-"} />
                    </div>
                    <div className="flex-row gap-3">
                        <InfoItem className="flex-one-in-row" label="Summer Peak Definition" value={trmDetails?.defSummerPeak ?? "-"} />
                        <InfoItem className="flex-one-in-row" label="Winter Peak Definition" value={trmDetails?.defWinterPeak ?? "-"} />
                    </div>
                </div>
                <div className="flex-column w-30 gap-3 px-4" style={{ borderLeft: "1px solid var(--theme-base-border)" }}>
                    <InfoItem label="Links" value={trmLinksElement} />
                    <InfoItem label="Contacts" value={contactsElement} />
                </div>
            </div>
        </Tile>
    );
};

export type TrmEditTab = (typeof TrmEditTabs)[keyof typeof TrmEditTabs]["id"];
