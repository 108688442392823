import { isEmpty } from "lodash";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { useGetMeasuresByTrmQuery, useGetTrmsQuery } from "store/apiSlice";

import { useAppDispatch } from "store/hooks";

import { selectorChange } from "pages/BenchmarkTrm/benchmarkTrmSlice";

import { IdsDropdown, IdsText, IdsCheckbox } from "@emergn-infinity/ids-react";

import { Tile } from "components/Tile";

import { weatherIcon, checkmarkIcon, closeIcon } from "utils/icons";

import { RelevantAlgorithm } from "types";

export const Sidebar: React.FC<{
    selectedTrm: string;
    selectedMeasure: string;
    selectedSector: string;
    selectedVintage: string;
    selectedFuelType: string;
    selectedRelAlgorithms: RelevantAlgorithm[];
    selectTrmRef: React.RefObject<HTMLIdsDropdownElement>;
    isFetching: boolean;
    relAlgorithms?: RelevantAlgorithm[];
}> = ({ selectedTrm, selectedMeasure, selectedRelAlgorithms, selectTrmRef, isFetching, relAlgorithms }) => {
    const dispatch = useAppDispatch();

    const { data: trms, isLoading: isLoadingTrms } = useGetTrmsQuery({ status: true });

    const { data: measures, isLoading: isLoadingMeasures } = useGetMeasuresByTrmQuery(
        { trmNumber: selectedTrm, measureRelated: true },
        { skip: isEmpty(selectedTrm) },
    );

    const trmList = useMemo(() => {
        return (trms ?? []).map((trm) => ({
            value: trm.trmNumber,
            label: trm.trmFamiliarName,
        }));
    }, [trms]);

    const measureList = useMemo(() => {
        return (measures ?? []).map((measure) => ({
            value: measure.measureNumber,
            label: measure.measureName,
        }));
    }, [measures]);

    return (
        <div className="flex-column fill-height">
            <div className="flex-column gap-3 pb-4">
                <div className="flex-column gap-2">
                    <IdsText customClasses="sidebar-title" weight="bold">
                        Select TRM & Measure
                    </IdsText>
                    <IdsDropdown
                        ref={selectTrmRef}
                        idValue="select-trm"
                        isSearchable={!isLoadingTrms} // A hack to properly preselect the dropdown value
                        items={trmList}
                        initialSelectedItems={selectedTrm ? [selectedTrm] : []}
                        placeholder={isLoadingTrms ? "Loading" : "Type to Search"}
                        changeHandler={(value) =>
                            dispatch(
                                selectorChange({
                                    selector: "trm",
                                    value,
                                    label: trms?.find((trm) => trm.trmNumber === value)?.trmFamiliarName,
                                }),
                            )
                        }
                        clearHandler={() => dispatch(selectorChange({ selector: "trm", value: "", label: "" }))}
                    />
                    <IdsDropdown
                        key={`measure-dropdown-${selectedTrm}`}
                        idValue="select-measure"
                        isSearchable={!isLoadingMeasures} // A hack to properly preselect the dropdown value
                        isDisabled={isEmpty(selectedTrm)}
                        items={measureList}
                        initialSelectedItems={selectedMeasure ? [selectedMeasure] : []}
                        placeholder={isLoadingMeasures ? "Loading" : "Type to Search"}
                        changeHandler={(value) =>
                            dispatch(
                                selectorChange({
                                    selector: "measure",
                                    value,
                                    label: measures?.find((measure) => measure.measureNumber === value)?.measureName,
                                }),
                            )
                        }
                        clearHandler={() => dispatch(selectorChange({ selector: "measure", value: "", label: "" }))}
                    />
                </div>
            </div>

            {!isEmpty(selectedTrm) && !isEmpty(selectedMeasure) && relAlgorithms !== undefined && !isFetching && (
                <Tile
                    className="flex-one-in-column"
                    bodyClassName="h-0 with-scroll"
                    title="Compare with"
                    counter={selectedRelAlgorithms?.length > 0 ? selectedRelAlgorithms.length : undefined}
                >
                    {relAlgorithms.map((algorithm, index) => (
                        <div
                            key={`relevant-algorithm-${algorithm.algorithmNumber}`}
                            className={cn("flex-row px-3 py-2 gap-2 align-center", {
                                "border-bottom": index !== relAlgorithms.length - 1,
                            })}
                        >
                            <IdsCheckbox
                                idValue={algorithm.algorithmNumber}
                                hideLabel
                                defaultChecked={
                                    selectedRelAlgorithms.find((a) => a.algorithmNumber === algorithm.algorithmNumber) !== undefined
                                }
                                changeHandler={() => dispatch(selectorChange({ selector: "relevantAlgorithms", value: algorithm }))}
                                customClasses="no-shrink"
                                style={{
                                    width: "calc(var(--ids-checkbox-input-size) + var(--ids-checkbox-border-width))",
                                }}
                            />
                            <div className="flex-column flex-grow gap-1">
                                <div>
                                    <IdsText weight="bold" size="sm">
                                        {algorithm.trmFamiliarName}
                                    </IdsText>
                                    <IdsText weight="bold" size="sm">
                                        {algorithm.measure}
                                    </IdsText>
                                </div>
                                <div>
                                    <IdsText size="sm">
                                        <p className="text-italic">{algorithm.algorithmDescr}</p>
                                    </IdsText>
                                </div>
                            </div>
                            <div
                                className={cn("flex-row align-center gap-1 px-2 py-1 rounded-pill")}
                                title={`This TRM ${
                                    algorithm.isSameClimateZone ? "represents" : "DOES NOT represent"
                                } the same ASHRAE Climate Zone as ${trms?.find((trm) => trm.trmNumber === selectedTrm)?.trmFamiliarName ?? ""}`}
                                style={{
                                    backgroundColor: algorithm.isSameClimateZone
                                        ? "var(--ids-core-color-brand-e-50)"
                                        : "var(--ids-core-color-system-a-50)",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={weatherIcon}
                                    color={
                                        algorithm.isSameClimateZone
                                            ? "var(--ids-core-color-brand-e-800)"
                                            : "var(--ids-core-color-system-a-800)"
                                    }
                                />
                                {algorithm.isSameClimateZone ? (
                                    <FontAwesomeIcon icon={checkmarkIcon} color="var(--ids-core-color-brand-e-800)" />
                                ) : (
                                    <FontAwesomeIcon icon={closeIcon} color="var(--ids-core-color-system-a-800)" />
                                )}
                            </div>
                        </div>
                    ))}
                </Tile>
            )}
        </div>
    );
};
