import { isEmpty } from "lodash";
import { useCallback, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IdsText, IdsTag, IdsFieldWrapper, IdsPasswordInput, IdsTextInput, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { IconButton } from "components/IconButton";

import { useTwoFactorEnabledQuery } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";
import { windowAdd } from "store/window/actions";

import { editIcon, circleUserIcon } from "utils/icons";

import { useAutoFillDetect } from "utils/useAutoFillDetect";
import { windowContainerTypes } from "utils/window";

export const PasswordStep: React.FC<{
    userName: string;
    password: string;
    authCode?: string;
    errorMessage: string | null;
    onUserChange: () => void;
    onChange: (name: string, value: string) => void;
    onSignIn: React.FormEventHandler<HTMLFormElement>;
}> = ({ userName, password, authCode, errorMessage, onUserChange, onChange, onSignIn }) => {
    const dispatch = useAppDispatch();

    const passwordRef = useRef<HTMLElement | null>();

    const { data, isLoading } = useTwoFactorEnabledQuery({ userName });

    const isAuthCodeRequired = data !== undefined && data.twoFactorRequired && data.qrCodeExists;

    const isAutoFilled = useAutoFillDetect(["password"]);

    const onRender = useCallback(() => {
        if (!passwordRef.current) {
            setTimeout(() => {
                passwordRef.current = document.getElementById("password");
                passwordRef.current?.focus();
            }, 100);
        }
    }, []);

    const onForgotPassword = () => {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Login,
                name: "Forgot Password",
                component: "ForgotPassword",
                showHeader: false,
                showTabs: false,
                props: {
                    userName,
                },
            }),
        );
    };

    return (
        <form className="flex-column align-center gap-5" onSubmit={onSignIn}>
            <IdsText size="sm" weight="bold" component="h3">
                Welcome
            </IdsText>
            <IdsTag isOutline size="sm">
                <div className="flex-row align-center gap-3">
                    <FontAwesomeIcon icon={circleUserIcon} size="lg" color="var(--ids-semantic-ink-color-brand-a-accent)" fixedWidth />
                    <IdsText weight="bold">{userName}</IdsText>
                    <IconButton icon={editIcon} size="lg" title="Change User" onClick={onUserChange} />
                </div>
            </IdsTag>
            {!isLoading && (
                <div className="fill-width">
                    <IdsFieldWrapper
                        wrapperLabel="Password"
                        isInvalid={errorMessage !== null}
                        helperInvalidText={errorMessage ?? undefined}
                    >
                        <IdsPasswordInput
                            ref={onRender}
                            placeholder="Enter your password"
                            defaultValue={password}
                            changeHandler={(value) => onChange("password", value)}
                            rest={{
                                id: "password",
                                autoComplete: "current-password",
                                name: "password",
                            }}
                        />
                    </IdsFieldWrapper>
                    {isAuthCodeRequired && (
                        <IdsFieldWrapper wrapperLabel="Authentication Code">
                            <IdsTextInput
                                placeholder="Enter authentication code"
                                defaultValue={authCode}
                                changeHandler={(value) => onChange("authCode", value)}
                            />
                        </IdsFieldWrapper>
                    )}
                    <IdsButtonGroup position="justify">
                        <IdsButton variant="tertiary" padding="sm" clickHandler={onForgotPassword}>
                            Forgot Password?
                        </IdsButton>
                        <IdsButton
                            type="submit"
                            isDisabled={(!isAutoFilled && isEmpty(password)) || (isAuthCodeRequired && isEmpty(authCode))}
                        >
                            Sign In
                        </IdsButton>
                    </IdsButtonGroup>
                </div>
            )}
        </form>
    );
};
