import { isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    useCreateEulMutation,
    useGetEulQuery,
    useGetLookupByPremisesQuery,
    useGetSectorsQuery,
    useGetVintagesQuery,
    useUpdateEulMutation,
} from "store/apiSlice";

import {
    IdsFieldWrapper,
    IdsText,
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsModal,
    IdsDropdown,
    IdsRadioButtonGroup,
    IdsRadioButton,
    IdsTag,
} from "@emergn-infinity/ids-react";

import { ManageLookups } from "pages/ExploreTrms/ManageLookups";
import { EditFormTags } from "pages/ExploreTrms/EditFormTags";

import { EulStrategy as Strategy } from "utils/constants";
import { addIcon, infoIcon } from "utils/icons";
import { sanitizeAndSetNumericInput } from "utils/string";

import { EulUpdateModel, Measure } from "types";

const EUL_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

const initialManageLookups = {
    add: false,
    view: false,
};

export const MeasureLifeEdit: React.FC<{
    measure: Measure;
    eulNumber?: string;
    onClose: () => void;
}> = ({ measure, eulNumber, onClose }) => {
    const [manageLookups, setManageLookups] = useState(initialManageLookups);

    const [model, setModel] = useState<EulModel>();
    const sourceYearInputRef = useRef<HTMLInputElement>(null);
    const eulInputRef = useRef<HTMLInputElement>(null);
    const rulInputRef = useRef<HTMLInputElement>(null);

    const { data: eul, isLoading, isError: isEulError } = useGetEulQuery({ eulNumber: eulNumber! }, { skip: !eulNumber });
    const isLoadingEul = isLoading && !isEmpty(eulNumber);

    const [create, createStatus] = useCreateEulMutation();
    const [update, updateStatus] = useUpdateEulMutation();

    const { data: sectors, isLoading: isLoadingSectors } = useGetSectorsQuery({ trmNumber: measure.trmNumber, isVerified: true });
    const sectorsList = useMemo(
        () => sectors?.map((sector) => ({ label: sector.sectorName, value: sector.sectorNumber })) || [],
        [sectors],
    );

    const { data: vintages, isLoading: isLoadingVintages } = useGetVintagesQuery({ trmNumber: measure.trmNumber, vintageVerified: true });
    const vintagesList = useMemo(
        () => vintages?.map((vintage) => ({ label: vintage.vintageName, value: vintage.vintageNumber })) || [],
        [vintages],
    );

    const { data: lookups, isLoading: isLoadingLookups } = useGetLookupByPremisesQuery({ trmNumber: measure.trmNumber, lookupType: "EUL" });
    const lookupsList = useMemo(() => lookups?.map((lookup) => ({ label: lookup, value: lookup })) || [], [lookups]);

    const lookupBreadcrumb = useMemo(() => {
        let sector = "";
        let vintage = "";

        if (model?.sectorNumber) {
            sector = sectorsList.find((item) => item.value === model.sectorNumber)?.label ?? "";
        }
        if (model?.vintageNumber) {
            vintage = vintagesList.find((item) => item.value === model.vintageNumber)?.label ?? "";
        }

        return [
            <IdsTag key={sector} variant="brand-c" size="sm">
                {sector}
            </IdsTag>,
            <IdsTag key={vintage} size="sm">
                {vintage}
            </IdsTag>,
        ];
    }, [model, sectorsList, vintagesList]);

    if (isEmpty(model)) {
        if (!isEmpty(eulNumber) && !isEmpty(eul)) {
            const newModel = {
                ...eul,
                eul: eul.eul ? String(eul.eul) : undefined,
                rul: eul.rul ? String(eul.rul) : undefined,
                eulSourceYear: eul.eulSourceYear ? String(eul.eulSourceYear) : undefined,
            };

            setModel(newModel);
        }

        if (isEmpty(eulNumber)) {
            setModel({
                eulStrategy: Strategy.Deemed,
                measureNumber: measure.measureNumber,
                eulVerified: true,
                eulUnits: Units.Years,
            });
        }
    }

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (updateStatus.isLoading || createStatus.isLoading) {
            return;
        }

        if (model) {
            try {
                const eulModel = {
                    ...model,
                    eul: model.eul ? Number(model.eul) : undefined,
                    rul: model.rul ? Number(model.rul) : undefined,
                    eulSourceYear: model.eulSourceYear ? Number(model.eulSourceYear) : undefined,
                };

                if (eulNumber) {
                    await update({ eulNumber, eul: eulModel }).unwrap();
                } else {
                    await create({ eul: eulModel }).unwrap();
                }
                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const title = isEmpty(eulNumber) ? "Add Measure Life" : "Edit Measure Life";

    if (manageLookups.add || manageLookups.view) {
        return (
            <ManageLookups
                measure={measure}
                lookupType="EUL"
                title={title}
                breadcrumb={lookupBreadcrumb}
                lookupByPremise={manageLookups.view && model?.eulLookupBy ? model.eulLookupBy : undefined}
                onClose={() => setManageLookups(initialManageLookups)}
            />
        );
    }

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={EUL_MODAL_STYLE}>
                {(isLoadingEul || isLoadingSectors || isLoadingVintages || isLoadingLookups) && <IdsText size="md">Loading...</IdsText>}
                {isEulError && <IdsText size="md">Error loading Measure Life</IdsText>}
                {!isLoadingEul && !isLoadingSectors && !isLoadingVintages && !isLoadingLookups && !isEulError && model && (
                    <>
                        <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} />
                        <IdsFieldWrapper htmlFor="eul-sector" wrapperLabel="Sector" isRequired>
                            <IdsDropdown
                                idValue="eul-sector"
                                isSearchable
                                items={sectorsList}
                                placeholder={isLoadingSectors ? "Loading" : "Type to Search"}
                                initialSelectedItems={model.sectorNumber ? [model.sectorNumber] : []}
                                changeHandler={(value) => setModel({ ...model, sectorNumber: value })}
                                clearHandler={() => setModel({ ...model, sectorNumber: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-vintage" wrapperLabel="Vintage" isRequired>
                            <IdsDropdown
                                idValue="eul-vintage"
                                isSearchable
                                items={vintagesList}
                                placeholder={isLoadingVintages ? "Loading" : "Type to Search"}
                                initialSelectedItems={model.vintageNumber ? [model.vintageNumber] : []}
                                changeHandler={(value) => setModel({ ...model, vintageNumber: value })}
                                clearHandler={() => setModel({ ...model, vintageNumber: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-strategy" wrapperLabel="EUL Assigned by">
                            <IdsRadioButtonGroup horizontalOrientation>
                                <IdsRadioButton
                                    idValue={Strategy.Deemed}
                                    name="eul-strategy"
                                    label={Strategy.Deemed}
                                    defaultChecked={model.eulStrategy === Strategy.Deemed}
                                    changeHandler={(e: any) => setModel({ ...model, eulStrategy: e.target.id, eulLookupBy: undefined })}
                                />
                                <IdsRadioButton
                                    idValue={Strategy.Lookup}
                                    name="eul-strategy"
                                    label={Strategy.Lookup}
                                    defaultChecked={model.eulStrategy === Strategy.Lookup}
                                    changeHandler={(e: any) =>
                                        setModel({ ...model, eulStrategy: e.target.id, eul: undefined, rul: undefined })
                                    }
                                />
                                <IdsRadioButton
                                    idValue={Strategy.UserDefined}
                                    name="eul-strategy"
                                    label={Strategy.UserDefined}
                                    defaultChecked={model.eulStrategy === Strategy.UserDefined}
                                    changeHandler={(e: any) =>
                                        setModel({
                                            ...model,
                                            eulStrategy: e.target.id,
                                            eulLookupBy: undefined,
                                            eul: undefined,
                                            rul: undefined,
                                        })
                                    }
                                />
                                <IdsRadioButton
                                    idValue={Strategy.Equation}
                                    name="eul-strategy"
                                    label={Strategy.Equation}
                                    defaultChecked={model.eulStrategy === Strategy.Equation}
                                    changeHandler={(e: any) =>
                                        setModel({
                                            ...model,
                                            eulStrategy: e.target.id,
                                            eulLookupBy: undefined,
                                            eul: undefined,
                                            rul: undefined,
                                        })
                                    }
                                />
                            </IdsRadioButtonGroup>
                        </IdsFieldWrapper>
                        {model.eulStrategy === Strategy.Deemed && (
                            <div className="flex-row gap-3">
                                <IdsFieldWrapper
                                    htmlFor="eul"
                                    wrapperLabel="Effective Useful Life (EUL)"
                                    isRequired
                                    customClasses="flex-one"
                                >
                                    <IdsTextInput
                                        innerRef={eulInputRef}
                                        idValue="eul"
                                        defaultValue={String(model.eul ?? "")}
                                        changeHandler={(value) =>
                                            setModel({ ...model, eul: sanitizeAndSetNumericInput(value, eulInputRef) })
                                        }
                                    />
                                </IdsFieldWrapper>
                                <IdsFieldWrapper htmlFor="eul-rul" wrapperLabel="Remaining Useful Life (RUL)" customClasses="flex-one">
                                    <IdsTextInput
                                        innerRef={rulInputRef}
                                        idValue="eul-rul"
                                        defaultValue={String(model.rul ?? "")}
                                        changeHandler={(value) =>
                                            setModel({
                                                ...model,
                                                rul: sanitizeAndSetNumericInput(value, rulInputRef),
                                            })
                                        }
                                    />
                                </IdsFieldWrapper>
                            </div>
                        )}
                        {model.eulStrategy === Strategy.Lookup && (
                            <IdsFieldWrapper htmlFor="eul-lookup" wrapperLabel="Lookup Table" isRequired>
                                <IdsDropdown
                                    idValue="eul-lookup"
                                    isSearchable
                                    items={lookupsList}
                                    placeholder={isLoadingLookups ? "Loading" : "Type to Search"}
                                    initialSelectedItems={model.eulLookupBy ? [model.eulLookupBy] : []}
                                    changeHandler={(value) => setModel({ ...model, eulLookupBy: value })}
                                    clearHandler={() => setModel({ ...model, eulLookupBy: "" })}
                                />
                                <IdsButtonGroup position="justify" customClasses="pt-1">
                                    <IdsButton variant="tertiary" clickHandler={() => setManageLookups({ add: true, view: false })}>
                                        <div className="flex-row gap-2 align-center">
                                            <FontAwesomeIcon icon={addIcon} fixedWidth />
                                            Add New
                                        </div>
                                    </IdsButton>
                                    <IdsButton
                                        variant="tertiary"
                                        clickHandler={() => setManageLookups({ add: false, view: true })}
                                        isDisabled={isEmpty(model.eulLookupBy)}
                                    >
                                        <div className="flex-row gap-2 align-center">
                                            <FontAwesomeIcon icon={infoIcon} fixedWidth />
                                            View Details
                                        </div>
                                    </IdsButton>
                                </IdsButtonGroup>
                            </IdsFieldWrapper>
                        )}
                        <IdsFieldWrapper htmlFor="eul-units" wrapperLabel="Units" isRequired>
                            <IdsDropdown
                                idValue="eul-units"
                                isSearchable
                                items={[
                                    {
                                        label: Units.Hours,
                                        value: Units.Hours,
                                    },
                                    {
                                        label: Units.Years,
                                        value: Units.Years,
                                    },
                                ]}
                                placeholder="Type to Search"
                                initialSelectedItems={model.eulUnits ? [model.eulUnits] : []}
                                changeHandler={(value) => setModel({ ...model, eulUnits: value })}
                                clearHandler={() => setModel({ ...model, eulUnits: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-source" wrapperLabel="Source">
                            <IdsTextInput
                                idValue="eul-source"
                                defaultValue={model.eulSource}
                                changeHandler={(value) => setModel({ ...model, eulSource: value })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-source-year" wrapperLabel="Source Year">
                            <IdsTextInput
                                innerRef={sourceYearInputRef}
                                idValue="eul-source-year"
                                defaultValue={String(model.eulSourceYear ?? "")}
                                changeHandler={(value) =>
                                    setModel({
                                        ...model,
                                        eulSourceYear: sanitizeAndSetNumericInput(value, sourceYearInputRef, {
                                            maxLength: 4,
                                            allowDecimals: false,
                                        }),
                                    })
                                }
                            />
                        </IdsFieldWrapper>
                    </>
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSave}>
                        <>{updateStatus.isLoading || createStatus.isLoading ? "Saving..." : "Save"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

const Units = {
    Hours: "Hours",
    Years: "Years",
} as const;

type EulModel = Omit<EulUpdateModel, "eul" | "rul" | "eulSourceYear"> & {
    eul?: string;
    rul?: string;
    eulSourceYear?: string;
};
