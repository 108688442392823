import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsText, IdsButtonGroup } from "@emergn-infinity/ids-react";

import { IconButton } from "components/IconButton";

import { AccessLevels } from "utils/constants";
import { linkIcon, lockIcon, lockOpenIcon, deleteIcon, editIcon } from "utils/icons";

export const LinkCard: React.FC<{
    linkName: string;
    urlLink: string;
    availability?: string;
    onDelete?: () => void;
    onEdit?: () => void;
}> = ({ linkName, urlLink, availability, onDelete, onEdit }) => {
    return (
        <div className="flex-row border rounded-edges-rounder py-1 pr-3 pl-1">
            <div
                className="rounded-edges-round flex-row flex-one-in-row align-center px-3 py-4"
                style={{ backgroundColor: "var(--ids-semantic-background-color-brand-b-subtle-default)" }}
            >
                <FontAwesomeIcon icon={linkIcon} size="lg" color="var(--ids-semantic-border-color-neutral-subtle)" />
            </div>
            <div className="flex-row align-center fill-width min-w-0">
                <div className="flex-column fill-width no-overflow px-2">
                    {availability && (
                        <div className="flex-row align-center gap-2">
                            <FontAwesomeIcon
                                icon={availability === AccessLevels.Public ? lockOpenIcon : lockIcon}
                                size="xs"
                                color="var(--ids-semantic-ink-color-brand-b-subtlest)"
                            />
                            <IdsText size="sm" weight="bold" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                                {availability}
                            </IdsText>
                        </div>
                    )}
                    <IdsText weight="bold">
                        <>{linkName}</>
                    </IdsText>
                    <IdsText size="md" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                        <div className="text-truncate">{urlLink}</div>
                    </IdsText>
                </div>
                {onDelete && onEdit && (
                    <div className="pl-2" style={{ borderLeft: "1px solid var(--theme-base-border)" }}>
                        <IdsButtonGroup customClasses="flex-no-wrap">
                            <IconButton icon={deleteIcon} size="lg" onClick={onDelete} />
                            <IconButton icon={editIcon} size="lg" onClick={onEdit} />
                        </IdsButtonGroup>
                    </div>
                )}
            </div>
        </div>
    );
};
