import { isEmpty, isNil } from "lodash";
import { useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    useCreateAssumptionMutation,
    useGetAssumptionQuery,
    useUpdateAssumptionMutation,
    useGetLookupByPremisesQuery,
    useUpdateAssumptionEquationOrNameMutation,
    useGetAssumptionsQuery,
} from "store/apiSlice";

import {
    IdsFieldWrapper,
    IdsText,
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsModal,
    IdsDropdown,
    IdsCheckbox,
} from "@emergn-infinity/ids-react";

import { ManageLookups } from "pages/ExploreTrms/ManageLookups";
import { EditFormTags } from "pages/ExploreTrms/EditFormTags";

import { validateAlgorithm, getAccompanyingLayers, AlgorithmErrors } from "pages/ExploreTrms/utils";

import { VarStrategy as Strategy } from "utils/constants";
import { addIcon, infoIcon } from "utils/icons";
import { sanitizeAndSetNumericInput } from "utils/string";

import { Assumption, AssumptionModel, AssumptionUpdateModel, Measure, MeasureAlgorithm } from "types";

const VARIABLE_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

const initialManageLookups = {
    add: false,
    view: false,
};

export const VariableEdit: React.FC<{
    measure: Measure;
    algorithm: MeasureAlgorithm;
    assumptionNumber?: string;
    canEditVariableName?: boolean;
    onClose: () => void;
}> = ({ measure, algorithm, assumptionNumber, canEditVariableName = true, onClose }) => {
    const [manageLookups, setManageLookups] = useState(initialManageLookups);
    const [model, setModel] = useState<AssumptionModel>();
    const [errors, setErrors] = useState<AssumptionErrors>({});
    const [equationErrors, setEquationErrors] = useState<AlgorithmErrors>();

    const {
        data: assumption,
        isLoading: isLoadingAssumption,
        isError: isGetAssumptionError,
    } = useGetAssumptionQuery({ assumptionNumber: assumptionNumber! }, { skip: !assumptionNumber });

    const {
        data: assumptions,
        isLoading: isLoadingAssumptions,
        isError: isGetAssumptionsError,
    } = useGetAssumptionsQuery({ algorithmNumber: algorithm.algorithmNumber });

    const { data: lookups = [], isLoading: isLoadingLookups } = useGetLookupByPremisesQuery({
        trmNumber: measure.trmNumber,
        lookupType: "Assumption",
    });

    const [createAssumption, createAssumptionStatus] = useCreateAssumptionMutation();
    const [updateAssumption, updateAssumptionStatus] = useUpdateAssumptionMutation();
    const [updateAsmpEquationOrName, updateAsmpEquationOrNameStatus] = useUpdateAssumptionEquationOrNameMutation();

    const isLoading = (isLoadingAssumption && !isEmpty(assumptionNumber)) || isLoadingAssumptions || isLoadingLookups;

    const title = isEmpty(assumptionNumber) ? "Add Variable" : "Edit Variable";

    const lookupBreadcrumb = useMemo(() => {
        const variable = model?.variable ?? "";

        return [<IdsText key={variable}>{variable}</IdsText>];
    }, [model]);

    if (!isLoadingAssumption && isEmpty(model)) {
        if (assumption) {
            const newModel = {
                ...assumption,
                assignedValue: assumption.assignedValue ? String(assumption.assignedValue) : undefined,
                assignedValue_Min: assumption.assignedValue_Min ? String(assumption.assignedValue_Min) : undefined,
                assignedValue_Max: assumption.assignedValue_Max ? String(assumption.assignedValue_Max) : undefined,
                varSourceYear: assumption.varSourceYear ? String(assumption.varSourceYear) : undefined,
            };

            setModel(newModel);
        } else {
            setModel({
                algorithmNumber: algorithm.algorithmNumber,
                asmpVerified: true,
                overrideAllowed: false,
                varStrategy: Strategy.Deemed,
            });
        }
    }

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (createAssumptionStatus.isLoading || updateAssumptionStatus.isLoading || updateAsmpEquationOrNameStatus.isLoading) {
            return;
        }

        if (model) {
            const errors = validateAssumption(model);

            if (!isEmpty(errors) || (model.varStrategy === Strategy.Equation && !isEmpty(equationErrors))) {
                setErrors(errors);

                return;
            }

            try {
                const assumptionModel = {
                    ...model,
                    assignedValue: model.assignedValue ? Number(model.assignedValue) : undefined,
                    assignedValue_Min: model.assignedValue_Min ? Number(model.assignedValue_Min) : undefined,
                    assignedValue_Max: model.assignedValue_Max ? Number(model.assignedValue_Max) : undefined,
                    varSourceYear: model.varSourceYear ? Number(model.varSourceYear) : undefined,
                };

                let asmpNumber = assumptionNumber;

                if (asmpNumber) {
                    await updateAssumption({ assumptionNumber: asmpNumber, assumption: assumptionModel, clearAlgorithm: true });
                } else {
                    const response = await createAssumption({ assumption: assumptionModel }).unwrap();

                    asmpNumber = response.assumptionNumber;
                }

                if (model.variable && model.varStrategy === Strategy.Equation) {
                    const asmpWithEquations = assumptions?.filter((asmp) => asmp.equation) ?? [];

                    let accompanyingLayers = [
                        {
                            // Assumption that gets equation defined will have the lowest layer (layer = 1).
                            // It does not matter what layer gets set here, it will get reevaluated
                            // in getAccompanyingLayers function
                            assumptionNumber: asmpNumber,
                            layer: 0,
                        },
                    ];

                    accompanyingLayers = getAccompanyingLayers(model.variable, asmpWithEquations, accompanyingLayers);

                    const asmpModel = {
                        equation: model.equation,
                        accompanyingLayers,
                        algorithmNumber: algorithm.algorithmNumber,
                    };

                    await updateAsmpEquationOrName({ assumptionNumber: asmpNumber, assumption: asmpModel });
                }

                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onVariableFormChange = (value: AssumptionModel) => {
        setModel(value);

        const errors = validateAssumption(value);

        setErrors(errors);
    };

    if (manageLookups.add || manageLookups.view) {
        return (
            <ManageLookups
                measure={measure}
                lookupType="Assumption"
                title={title}
                breadcrumb={lookupBreadcrumb}
                lookupByPremise={manageLookups.view && model?.varLookupBy ? model.varLookupBy : undefined}
                sector={algorithm.sector}
                endUse={algorithm.endUse}
                vintage={algorithm.vintage}
                onClose={() => setManageLookups(initialManageLookups)}
            />
        );
    }

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={VARIABLE_MODAL_STYLE}>
                {isLoading && <IdsText size="md">Loading...</IdsText>}
                {isGetAssumptionError && isGetAssumptionsError && <IdsText size="md">Error loading Variable</IdsText>}
                {!isLoading && !isGetAssumptionError && !isGetAssumptionsError && (
                    <VariableForm
                        assumption={assumption}
                        isLoading={updateAssumptionStatus.isLoading || createAssumptionStatus.isLoading}
                        trmFamiliarName={measure.trmFamiliarName}
                        measureName={measure.measureName}
                        sector={algorithm.sector}
                        endUse={algorithm.endUse}
                        vintage={algorithm.vintage}
                        lookups={lookups}
                        model={model}
                        errors={errors}
                        equationErrors={equationErrors}
                        canEditName={canEditVariableName ?? !isEmpty(assumption)}
                        setModel={onVariableFormChange}
                        setEquationErrors={model?.varStrategy === Strategy.Equation ? setEquationErrors : undefined}
                        onAddLookupTable={() => setManageLookups({ add: true, view: false })}
                        onViewLookupTable={() => setManageLookups({ add: false, view: true })}
                    />
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSave}>
                        <>
                            {createAssumptionStatus.isLoading ||
                            updateAssumptionStatus.isLoading ||
                            updateAsmpEquationOrNameStatus.isLoading
                                ? "Saving..."
                                : "Save"}
                        </>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

export const VariableForm: React.FC<{
    isLoading: boolean;
    assumption?: Assumption;
    trmFamiliarName?: string;
    measureName?: string;
    sector?: string;
    endUse?: string;
    vintage?: string;
    lookups: string[];
    model?: AssumptionModel;
    errors?: AssumptionErrors;
    equationErrors?: AlgorithmErrors;
    canEditName?: boolean;
    setModel: (model: AssumptionModel) => void;
    setEquationErrors?: (equationErrors: AlgorithmErrors) => void;
    onAddLookupTable: () => void;
    onViewLookupTable: () => void;
    onSaveEquation?: () => Promise<void>;
}> = ({
    isLoading,
    assumption,
    trmFamiliarName,
    measureName,
    sector,
    endUse,
    vintage,
    lookups,
    model,
    errors,
    equationErrors,
    canEditName,
    setModel,
    setEquationErrors,
    onAddLookupTable,
    onViewLookupTable,
    onSaveEquation,
}) => {
    const [minMaxEnabled, setMinMaxEnabled] = useState(!isNil(assumption?.assignedValue_Min) || !isNil(assumption?.assignedValue_Max));

    const assignedValueRef = useRef<HTMLInputElement>(null);
    const assignedValueMinRef = useRef<HTMLInputElement>(null);
    const assignedValueMaxRef = useRef<HTMLInputElement>(null);
    const sourceYearInputRef = useRef<HTMLInputElement>(null);

    const isEquationInvalid =
        !isEmpty(equationErrors?.brackets) ||
        !isEmpty(equationErrors?.underscore) ||
        !isEmpty(equationErrors?.multiplication) ||
        !isEmpty(equationErrors?.comma) ||
        !isEmpty(equationErrors?.duplicate) ||
        !isEmpty(equationErrors?.algorithm);

    const equationInvalidText =
        equationErrors?.brackets ||
        equationErrors?.underscore ||
        equationErrors?.multiplication ||
        equationErrors?.duplicate ||
        equationErrors?.comma ||
        equationErrors?.algorithm;

    const lookupsList = useMemo(() => lookups?.map((lookup) => ({ label: lookup, value: lookup })) || [], [lookups]);

    if (isEmpty(model)) {
        return null;
    }

    const onStrategyChange = (value: string) => {
        switch (value) {
            case Strategy.Deemed:
                setModel({
                    ...model,
                    varStrategy: value,
                    varLookupBy: undefined,
                    overrideAllowed: false,
                    assignedValue_Min: undefined,
                    assignedValue_Max: undefined,
                });

                break;
            case Strategy.Lookup:
                setModel({
                    ...model,
                    varStrategy: value,
                    assignedValue: undefined,
                    assignedValue_Min: undefined,
                    assignedValue_Max: undefined,
                });

                break;
            case Strategy.InputRequired:
            case Strategy.InputAllowed:
                setModel({
                    ...model,
                    varStrategy: value,
                    varLookupBy: undefined,
                    assignedValue: undefined,
                    overrideAllowed: true,
                    assignedValue_Min: model.assignedValue_Min,
                    assignedValue_Max: model.assignedValue_Max,
                });

                break;
            case Strategy.Binary:
                setModel({
                    ...model,
                    varStrategy: value,
                    varLookupBy: undefined,
                    assignedValue: undefined,
                    overrideAllowed: true,
                    varUnits: undefined,
                    assignedValue_Min: undefined,
                    assignedValue_Max: undefined,
                });

                break;
            case Strategy.Equation:
            case Strategy.MissingFromTrm:
                setModel({
                    ...model,
                    varStrategy: value,
                    varLookupBy: undefined,
                    assignedValue: undefined,
                    assignedValue_Min: undefined,
                    assignedValue_Max: undefined,
                });

                break;
            default:
                break;
        }
    };

    const onEquationChange = (value: string) => {
        setModel({ ...model, equation: value });

        const errors = validateAlgorithm(value);

        setEquationErrors?.(errors);
    };

    const onMinMaxChange = (e: any) => {
        if (!e.target.checked) {
            setModel({ ...model, assignedValue_Min: undefined, assignedValue_Max: undefined });
        }

        setMinMaxEnabled(e.target.checked);
    };

    return (
        <>
            <EditFormTags trmName={trmFamiliarName} measureName={measureName} sector={sector} endUse={endUse} vintage={vintage} />
            <IdsFieldWrapper
                htmlFor="variable-name"
                wrapperLabel="Variable"
                isReadonly={!canEditName}
                isRequired
                isInvalid={canEditName && !isEmpty(errors?.variable)}
                helperInvalidText={errors?.variable}
            >
                <IdsTextInput
                    idValue="variable-name"
                    defaultValue={model.variable}
                    changeHandler={(value) => setModel({ ...model, variable: value })}
                />
            </IdsFieldWrapper>
            <IdsFieldWrapper
                htmlFor="variable-desc"
                wrapperLabel="Description"
                isRequired
                isInvalid={!isEmpty(errors?.variableDesc)}
                helperInvalidText={errors?.variableDesc}
            >
                <IdsTextInput
                    idValue="variable-desc"
                    defaultValue={model.variableDesc}
                    changeHandler={(value) => setModel({ ...model, variableDesc: value })}
                />
            </IdsFieldWrapper>
            <IdsFieldWrapper
                htmlFor="variable-strategy"
                wrapperLabel="Assignment Strategy"
                isRequired
                isInvalid={!isEmpty(errors?.varStrategy)}
                helperInvalidText={errors?.varStrategy}
            >
                <IdsDropdown
                    idValue="variable-strategy"
                    defaultValue={model.varStrategy}
                    isSearchable
                    initialSelectedItems={
                        model.varStrategy && Object.values(Strategy).includes(model.varStrategy) ? [model.varStrategy] : []
                    }
                    placeholder="Type to Search"
                    changeHandler={onStrategyChange}
                    items={Object.values(Strategy).map((value) => ({ label: value, value: value }))}
                />
            </IdsFieldWrapper>
            {model.varStrategy === Strategy.Equation && (
                <IdsCheckbox
                    customClasses="pb-3"
                    idValue="variable-override-allowed"
                    label="Allow user input to bypass the defined sub equation"
                    defaultChecked={model.overrideAllowed}
                    changeHandler={(e: any) => setModel({ ...model, overrideAllowed: e.target?.checked ?? false })}
                />
            )}
            {[Strategy.Deemed, Strategy.InputAllowed].includes(model.varStrategy) && (
                <div className="flex-row gap-4">
                    <IdsFieldWrapper
                        customClasses="w-50"
                        htmlFor="variable-assigned-value"
                        wrapperLabel="Assigned Value"
                        isRequired
                        isInvalid={!isEmpty(errors?.assignedValue)}
                        helperInvalidText={errors?.assignedValue}
                    >
                        <IdsTextInput
                            innerRef={assignedValueRef}
                            idValue="variable-assigned-value"
                            defaultValue={String(model.assignedValue ?? "")}
                            changeHandler={(value) =>
                                setModel({ ...model, assignedValue: sanitizeAndSetNumericInput(value, assignedValueRef) })
                            }
                        />
                    </IdsFieldWrapper>
                    <IdsFieldWrapper
                        customClasses="w-50"
                        htmlFor="variable-units"
                        wrapperLabel="Units"
                        isInvalid={!isEmpty(errors?.varUnits)}
                        helperInvalidText={errors?.varUnits}
                    >
                        <IdsTextInput
                            idValue="variable-units"
                            defaultValue={model.varUnits}
                            changeHandler={(value) => setModel({ ...model, varUnits: value })}
                        />
                    </IdsFieldWrapper>
                </div>
            )}
            {model.varStrategy === Strategy.Lookup && (
                <>
                    <IdsFieldWrapper
                        htmlFor="variable-lookup"
                        wrapperLabel="Lookup Table"
                        isRequired
                        isInvalid={!isEmpty(errors?.varLookupBy)}
                        helperInvalidText={errors?.varLookupBy}
                    >
                        <IdsDropdown
                            idValue="variable-lookup"
                            isSearchable
                            items={lookupsList}
                            placeholder="Type to Search"
                            initialSelectedItems={isNil(model.varLookupBy) ? [] : [model.varLookupBy]}
                            changeHandler={(value) => setModel({ ...model, varLookupBy: value })}
                            clearHandler={() => setModel({ ...model, varLookupBy: undefined })}
                        />
                        <IdsButtonGroup position="justify" customClasses="pt-1">
                            <IdsButton variant="tertiary" clickHandler={onAddLookupTable}>
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={addIcon} fixedWidth />
                                    Add New
                                </div>
                            </IdsButton>
                            <IdsButton variant="tertiary" clickHandler={onViewLookupTable} isDisabled={isEmpty(model.varLookupBy)}>
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={infoIcon} fixedWidth />
                                    View Details
                                </div>
                            </IdsButton>
                        </IdsButtonGroup>
                    </IdsFieldWrapper>
                    <IdsCheckbox
                        customClasses="pb-3"
                        idValue="variable-override-allowed"
                        label="User Input Allowed"
                        defaultChecked={model.overrideAllowed}
                        changeHandler={(e: any) => setModel({ ...model, overrideAllowed: e.target?.checked ?? false })}
                    />
                </>
            )}
            {![Strategy.Binary, Strategy.Equation, Strategy.Lookup].includes(model.varStrategy) && model.overrideAllowed && (
                <>
                    <IdsCheckbox
                        customClasses="pb-3"
                        idValue="min-max-enabled"
                        label="Set Min. and Max. value"
                        defaultChecked={minMaxEnabled}
                        changeHandler={onMinMaxChange}
                    />
                    {minMaxEnabled && (
                        <div className="flex-row gap-4">
                            <IdsFieldWrapper customClasses="w-50" htmlFor="variable-assigned-value-min" wrapperLabel="Min. value">
                                <IdsTextInput
                                    innerRef={assignedValueMinRef}
                                    idValue="variable-assigned-value-min"
                                    defaultValue={String(model.assignedValue_Min ?? "")}
                                    changeHandler={(value) =>
                                        setModel({ ...model, assignedValue_Min: sanitizeAndSetNumericInput(value, assignedValueMinRef) })
                                    }
                                />
                            </IdsFieldWrapper>
                            <IdsFieldWrapper customClasses="w-50" htmlFor="variable-assigned-value-max" wrapperLabel="Max. value">
                                <IdsTextInput
                                    innerRef={assignedValueMaxRef}
                                    idValue="variable-assigned-value-max"
                                    defaultValue={String(model.assignedValue_Max ?? "")}
                                    changeHandler={(value) =>
                                        setModel({ ...model, assignedValue_Max: sanitizeAndSetNumericInput(value, assignedValueMaxRef) })
                                    }
                                />
                            </IdsFieldWrapper>
                        </div>
                    )}
                </>
            )}
            {/* 
                Exclude Binary, Deemed and InputAllowed strategies:
                    - Binary - does not need units field
                    - Deemed, InputAllowed - units field gets rendered next to assigned value field    
            */}
            {[Strategy.Equation, Strategy.InputRequired, Strategy.Lookup, Strategy.MissingFromTrm].includes(model.varStrategy) && (
                <IdsFieldWrapper
                    htmlFor="variable-units"
                    wrapperLabel="Units"
                    isInvalid={!isEmpty(errors?.varUnits)}
                    helperInvalidText={errors?.varUnits}
                >
                    <IdsTextInput
                        idValue="variable-units"
                        defaultValue={model.varUnits}
                        changeHandler={(value) => setModel({ ...model, varUnits: value })}
                    />
                </IdsFieldWrapper>
            )}
            {model.varStrategy === Strategy.Equation && (
                <IdsFieldWrapper
                    htmlFor="sub-equation"
                    wrapperLabel="Sub equation"
                    isInvalid={isEquationInvalid}
                    helperInvalidText={equationInvalidText}
                    helperText={onSaveEquation ? "Save sub equations to configure variables" : undefined}
                    helperIcon={onSaveEquation ? "ui-alert-information_circle" : undefined}
                >
                    <div className="flex-row gap-3">
                        <IdsTextInput
                            customClasses="fill-width"
                            idValue="sub-equation"
                            defaultValue={model.equation ?? ""}
                            isInvalid={isEquationInvalid}
                            changeHandler={onEquationChange}
                        />
                        {onSaveEquation && (
                            <IdsButton variant="secondary" padding="lg" clickHandler={onSaveEquation}>
                                <>{isLoading ? "Saving..." : "Save"}</>
                            </IdsButton>
                        )}
                    </div>
                </IdsFieldWrapper>
            )}
            <IdsFieldWrapper htmlFor="variable-source" wrapperLabel="Source">
                <IdsTextInput
                    idValue="variable-source"
                    defaultValue={model.varSource}
                    changeHandler={(value) => setModel({ ...model, varSource: value })}
                />
            </IdsFieldWrapper>
            <IdsFieldWrapper htmlFor="variable-source-year" wrapperLabel="Year of Source">
                <IdsTextInput
                    innerRef={sourceYearInputRef}
                    idValue="variable-source-year"
                    defaultValue={String(model.varSourceYear ?? "")}
                    changeHandler={(value) =>
                        setModel({
                            ...model,
                            varSourceYear: sanitizeAndSetNumericInput(value, sourceYearInputRef, {
                                maxLength: 4,
                                allowDecimals: false,
                            }),
                        })
                    }
                />
            </IdsFieldWrapper>
        </>
    );
};

export const validateAssumption = (model: AssumptionModel) => {
    const errors: AssumptionErrors = {};

    if (isEmpty(model.variable)) {
        errors.variable = "Variable is required";
    }

    if (isEmpty(model.variableDesc)) {
        errors.variableDesc = "Description is required";
    }

    if (isEmpty(model.varStrategy) || !Object.values(Strategy).includes(model.varStrategy)) {
        errors.varStrategy = "Assignment strategy is required";
    }

    if ([Strategy.Deemed, Strategy.InputAllowed].includes(model.varStrategy) && isNil(model.assignedValue)) {
        errors.assignedValue = "Assigned value is required";
    }

    if (model.varStrategy === Strategy.Lookup && isEmpty(model.varLookupBy)) {
        errors.varLookupBy = "Lookup table is required";
    }

    return errors;
};

type AssumptionErrors = { [K in keyof AssumptionUpdateModel]?: string };
